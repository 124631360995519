import { render, staticRenderFns } from "./WechatBind.vue?vue&type=template&id=9ff77aba&"
import script from "./WechatBind.vue?vue&type=script&lang=js&"
export * from "./WechatBind.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/nantong-hrpark-prod-mobile-company(微信公众号-企业）/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('9ff77aba', component.options)
    } else {
      api.reload('9ff77aba', component.options)
    }
    module.hot.accept("./WechatBind.vue?vue&type=template&id=9ff77aba&", function () {
      api.rerender('9ff77aba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/WechatBind.vue"
export default component.exports